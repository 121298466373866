
import { Prop, Vue, Emit, Component, Watch } from 'vue-property-decorator';
import { SubRole, Role } from '@/typings/domain';
import PopupDialogDefault from '@/components/ui/popup-dialog/PopupDialogDefault.vue';
import mode from '@/store/modules/mode/mode';
import RolesModule from '@/store/modules/roles/RolesModule';
import GeneralModule from '@/store/modules/general/GeneralModule';
@Component({
    components: {
        PopupDialogDefault,
    },
})
export default class InviteSubmenu extends Vue {
    @Prop({ default: [], required: true }) actualRoles: Array<Role>;
    @Prop({ default: false }) chooseByID: boolean;
    @Prop() submenuStyles: object;
    @Prop() selected: Array<number>;

    public getCurrentHoveredIndex = -1;
    public сontextMenuClose = false;
    public specialisationValue = ''
    public correctionLeft = 0;
    public correctionTop = 0;
    public firstOpenClose = false;
    public submenuPosition: Array<string> = [];
    public getCurrentHoveredItem = {};
		public isLoading = false;

    @Emit('add')
    addSpecialisation(spec: SubRole | number) {
        return spec;
    }
    createSpecialisation(e: any) {
        console.log(e)
    }
    currentHoveredItem(item:any,index: number)
    {
        this.getCurrentHoveredIndex = index;
        this.getCurrentHoveredItem = item
    }
    async mouseover(e: Event, idx: number) {
        
        this.firstOpenClose = true;
        const target = e.target as HTMLElement;
        const wrapper = target.closest('.roles-menu__wrapper');
        await this.$nextTick();
        if(this.$refs.submenu)
        {
            const subMenuPosition = this.$refs.submenu as HTMLElement
            this.submenuPosition[idx] = (target.offsetTop - wrapper.scrollTop - subMenuPosition.offsetHeight + 20) + 'px';
            this.submenuPosition = [...this.submenuPosition];
        }
    }
    
    @Emit('specsCreated')
    showContextToCreate(value: number) {
        mode.setValue(value);
        mode.setPopUpStatus();
        return true;
    }
    mounted()
    {
        const intersectionObserver = new IntersectionObserver(entries => {
            if (entries[0].intersectionRatio <= 0) return;
            // this.loadItems();
						this.paginateRoles()
        });
        intersectionObserver.observe(document.querySelector('.roles-menu__item-end'));
        const el = this.$refs.contextHeight as HTMLElement
        GeneralModule.setPopupContextHeight(el.offsetHeight)
    }
		get rolesPage()
		{
				return RolesModule.paginationPage;
		}
		paginateRoles()
		{ 
				if(!RolesModule.endPaginateRoles) RolesModule.fetchRoles({page: this.rolesPage})
		}

    @Emit('loadItems')
    loadItems()
    {
        const page = this.paginationPage + 1
        RolesModule.setPaginationPage(page)
        return this.paginationPage;
    }
    get paginationPage()
    {
        return RolesModule.paginationPage
    }

    @Watch('actualRoles')
    onActualRolesChanges()
    {
        this.currentHoveredItem(this.actualRoles[this.getCurrentHoveredIndex], this.getCurrentHoveredIndex)
    }
}
