
import { SubRole, Role } from '@/typings/domain';
import { Prop, Vue, Emit, Component, Watch } from 'vue-property-decorator';
import PopupDialogDefault from '@/components/ui/popup-dialog/PopupDialogDefault.vue';
import mode from '@/store/modules/mode/mode';
import RolesModule from '@/store/modules/roles/RolesModule';
import GeneralModule from '@/store/modules/general/GeneralModule';

@Component({
    components: {
        PopupDialogDefault,
    },
})
export default class Submenu extends Vue
{
	@Prop({ default: [], required: true }) actualRoles: Array<Role>;
	@Prop({ default: false }) chooseByID: boolean;
	@Prop( { default: false }) hideAllSpecs: boolean;
	@Prop( { default: false }) hideForAll: boolean;
	@Prop() submenuStyles: object;
	@Prop() selected: Array<number>;

	public getCurrentHoveredIndex = -1;
    public сontextMenuClose = false;
    public specialisationValue = '';
    public correctionLeft = 0;
    public correctionTop = 0;
    public firstOpenClose = false;
    public submenuPosition: Array<string> = [];
    public getCurrentHoveredItem = {};
	
	@Emit('add')
	addSpecialisation(spec: SubRole | number)
	{
		return spec;
	}
	
	@Emit('forAll')
	selectAllRoles(): boolean
	{
		return true;
	}
	
	@Emit('allSpecs')
	selectAllSpecs(list: Array<SubRole>): {ids: Array<number>, roleName: string, list: Array<SubRole>}
	{
		const ids = list.map((item: SubRole) => item.id);
		const roleName = this.actualRoles.find(el => el.id === list[0].role_id).name
		
		return {ids, roleName, list};
	}

	createSpecialisation(e: any) {
        console.log(e)
    }
    
	currentHoveredItem(item:any,index: number)
    {
        this.getCurrentHoveredIndex = index;
        this.getCurrentHoveredItem = item
    }

	mouseover(e: Event, idx: number)
	{
		const target = e.target as HTMLElement;
		const wrapper = target.closest('.roles-menu__wrapper');

		this.submenuPosition[idx] = (target.offsetTop - wrapper.scrollTop - 16) + 'px';
		this.submenuPosition = [...this.submenuPosition];
	}

    @Emit('specsCreated')
    showContextToCreate(value: number) {
        mode.setValue(value);
        mode.setPopUpStatus();
        return true;
    }
    mounted()
    {
        const intersectionObserver = new IntersectionObserver(entries => {
						if (entries[0].intersectionRatio <= 0) return;
						this.paginateRoles()
        });
        intersectionObserver.observe(document.querySelector('.roles-menu__item-end'));
        const el = this.$refs.contextHeight as HTMLElement
        GeneralModule.setPopupContextHeight(el.offsetHeight)
    }
		get rolesPage()
		{
				return RolesModule.paginationPage;
		}
		paginateRoles()
		{ 
				if(!RolesModule.endPaginateRoles) RolesModule.fetchRoles({page: this.rolesPage})
		}
    @Emit('loadItems')
    loadItems()
    {
        const page = this.paginationPage + 1
        RolesModule.setPaginationPage(page)
        return this.paginationPage;
    }
    get paginationPage()
    {
        return RolesModule.paginationPage
    }

    @Watch('actualRoles')
    onActualRolesChanges()
    {
        this.currentHoveredItem(this.actualRoles[this.getCurrentHoveredIndex], this.getCurrentHoveredIndex)
    }
}
